<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-10/12 mx-auto" v-if="!isLoading">
      <router-link
        :to="{ name: 'list-vacancies' }"
        class="bg-green-1000 text-white px-6 py-3 rounded rounded-md border border-gray-400 focus:outline-none"
      >
        <svg
          style="display: initial;"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
        voltar
      </router-link>
      <div class="p-4 flex">
        <h1 class="text-3xl">
          Editar Oportunidade
        </h1>
      </div>
      <form-wizard
        @on-complete="onComplete"
        :start-index="0"
        color="#69CA5E"
        title=""
        subtitle=""
        ref="form"
        next-button-text="Próximo"
        back-button-text="Anterior"
        finish-button-text="Finalizar"
      >
        <tab-content title="Oportunidade" icon="ri-seedling-line">
          <div
            class="w-12/12 border border-green-600  rounded-lg py-3 px-5 mx-auto mt-5"
          >
            <div class="flex flex-row justify-start mb-5">
              <div class="mr-5">
                <p>Situação da Oportunidade:</p>
              </div>
              <div class="flex flex-row items-baseline mr-5">
                <input
                  type="radio"
                  name="visible"
                  id="one"
                  value="1"
                  v-model="vacancy.visible"
                  class="mr-1"
                />
                <label class="mb-2" for="situation">Ativo</label>
              </div>
              <div class="flex flex-row items-baseline mr-5">
                <input
                  type="radio"
                  name="visible"
                  id="one"
                  value="0"
                  v-model="vacancy.visible"
                  class="mr-1"
                />
                <label class="mb-2" for="situation">Inativo</label>
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-full flex-col mr-5">
                <label class="mb-2" for="office">Cargo</label>
                <input
                  v-model="$v.vacancy.office.$model"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="office"
                  type="text"
                  placeholder="Nome do cargo"
                  autocomplete="off"
                />
                <div v-if="$v.vacancy.office.$error">
                  <div class="error" v-if="!$v.vacancy.office.required">
                    Campo obrigatório.
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="description_vacancy"
                  >Principais atividades da vaga</label
                >
                <textarea
                  v-model="$v.vacancy.description_vacancy.$model"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="description_vacancy"
                  autocomplete="off"
                ></textarea>
                <div v-if="$v.vacancy.description_vacancy.$error">
                  <div
                    class="error"
                    v-if="!$v.vacancy.description_vacancy.required"
                  >
                    Campo obrigatório.
                  </div>
                </div>
              </div>

              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="requirements"
                  >Requisitos necessários</label
                >
                <textarea
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="requirements"
                  v-model="$v.vacancy.requirements.$model"
                  autocomplete="off"
                ></textarea>
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="vacancies_available"
                  >Oportunidades</label
                >
                <input
                  v-model="$v.vacancy.vacancies_available.$model"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="vacancies_available"
                  type="text"
                  placeholder="Quantidade de oportunidades"
                  autocomplete="off"
                  v-mask="'###'"
                />
                <div v-if="$v.vacancy.vacancies_available.$error">
                  <div
                    class="error"
                    v-if="!$v.vacancy.vacancies_available.required"
                  >
                    Campo obrigatório.
                  </div>
                  <div
                    class="error"
                    v-if="!$v.vacancy.vacancies_available.between"
                  >
                    Quantidade de Oportunidade deve ser maior que 1!
                  </div>
                </div>
              </div>
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="contracting_regime"
                  >Regime de Contratação</label
                >
                <multiselect
                  id="contracting_regime"
                  v-model="$v.vacancy.contracting_regime.selected.$model"
                  track-by="label"
                  label="label"
                  select-label="Selecionar"
                  selected-label="Selecionado"
                  deselect-label="Remover"
                  :searchable="false"
                  placeholder="Selecione um tipo"
                  :options="vacancy.contracting_regime.options"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.label }}
                  </template>
                  <span slot="noOptions">Nenhum registro encontrado.</span>
                </multiselect>
                <div v-if="$v.vacancy.contracting_regime.$error">
                  <div
                    class="error"
                    v-if="!$v.vacancy.contracting_regime.selected.required"
                  >
                    Campo obrigatório.
                  </div>
                </div>
              </div>
              <div class="flex w-6/12 flex-col mr-5">
                <span
                  ><input
                    type="checkbox"
                    v-model="hiringTimeOption"
                    name="hiring_time_option"
                    value="Tempo de Contratação"
                  />
                  Tempo de Contratação</span
                >
                <input
                  v-model="$v.vacancy.hiring_time.$model"
                  v-show="hiringTimeOption"
                  class="appearance-none mt-2 block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="hiring_time"
                  type="text"
                  placeholder="Ex.: 6 meses"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-2/12 flex-col mr-5">
                <label class="mb-2" for="office_hours">Horário</label>
                <input
                  v-model="$v.vacancy.office_hours.$model"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="office_hours"
                  type="text"
                  placeholder="9h às 18h"
                  autocomplete="off"
                />
                <div v-if="$v.vacancy.office_hours.$error">
                  <div class="error" v-if="!$v.vacancy.office_hours.required">
                    Campo obrigatório.
                  </div>
                </div>
              </div>
              <div class="flex w-2/12 flex-col mr-5">
                <div class="flex items-start">
                  <label class="mb-2" for="salary">Salário De</label>
                  <i
                    class="ri-information-line ml-2"
                    v-tooltip="{
                      content:
                        'Caso seja um salário fixo, informe nesse campo e deixe o proximo em branco.',
                      placement: 'right'
                    }"
                  ></i>
                </div>
                <input
                  v-model.lazy="vacancy.salary"
                  v-money="money"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="salary"
                  type="text"
                  placeholder=""
                  autocomplete="off"
                />
              </div>
              <div class="flex w-2/12 flex-col mr-5">
                <label class="mb-2" for="salary">Salário Até</label>
                <input
                  v-model.lazy="vacancy.salary_until"
                  v-money="money"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="salary"
                  type="text"
                  placeholder=""
                  autocomplete="off"
                />
              </div>
              <div class="flex w-6/12 flex-col mr-">
                <label class="flex items-center mb-2" for="education-level">
                  Escolaridade Mínima
                </label>
                <multiselect
                  id="education-level"
                  v-model="vacancy.schooling.selected"
                  deselect-label="Remover"
                  track-by="name"
                  label="name"
                  select-label="Selecionar"
                  selected-label="Selecionado"
                  placeholder="Selecione uma escolaridade"
                  :options="vacancy.schooling.options"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.name }}
                  </template>
                  <span slot="noOptions">Nenhuma escolaridade encontrada.</span>
                </multiselect>
                <div v-if="$v.vacancy.schooling.$error">
                  <div class="error" v-if="!$v.vacancy.schooling.required">
                    Campo obrigatório.
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="validity_from"
                  >Periodo de inscrição DE</label
                >
                <input
                  v-model="$v.vacancy.validity_from.$model"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="validity_from"
                  type="date"
                  placeholder=""
                  autocomplete="off"
                />
                <div v-if="$v.vacancy.validity_from.$error">
                  <div class="error" v-if="!$v.vacancy.validity_from.required">
                    Campo obrigatório.
                  </div>
                </div>
              </div>
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="validity_to"
                  >Periodo de inscrição ATÉ</label
                >
                <input
                  v-model="$v.vacancy.validity_to.$model"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="validity_to"
                  type="date"
                  placeholder=""
                  autocomplete="off"
                  @change="alterStatus"
                />
                <div v-if="$v.vacancy.validity_to.$error">
                  <div class="error" v-if="!$v.vacancy.validity_to.required">
                    Campo obrigatório.
                  </div>
                  <div class="error" v-if="!$v.vacancy.validity_to.valiDate">
                    Periodo de inscrição ATÉ deve ser maior que o Periodo de
                    inscrição DE
                  </div>
                </div>
              </div>
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="start_activities"
                  >Data Prevista de Inicio</label
                >
                <input
                  v-model="vacancy.start_activities"
                  class="appearance-none block w-full text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="start_activities"
                  type="date"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="keywords">Palavras chaves</label>
                <vue-tags-input
                  id="keywords"
                  class="outline-none rounded-md border w-full"
                  rows="5"
                  ref="keywords"
                  v-model="vacancy.search"
                  :keywords="vacancy.keywords"
                  @input="searchKeyword"
                  placeholder="Adicione as palavras chaves desejadas"
                  @tags-changed="newKeywords => (vacancy.keyword = newKeywords)"
                  autocomplete="off"
                />
              </div>
            </div>
            <div
              v-if="vacancy.keywords.length > 0"
              class="flex flex-col w-full bg-white rounded-lg mt-4 py-2 px-4 max-h-64 overflow-scroll"
            >
              <div
                class="cursor-pointer my-1 hover:bg-gray-100"
                v-for="keyword in vacancy.keywords"
                :key="keyword"
                @click="selectKeyword(keyword)"
              >
                {{ keyword }}
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-full flex-col mr-5">
                <label class="mb-2" for="benefits">Benéficios</label>

                <span v-for="benefit in benefits" :key="benefit.id">
                  <input
                    v-model="$v.vacancy.benefits.$model"
                    type="checkbox"
                    :value="benefit.id"
                  />
                  {{ benefit.name }}
                </span>
              </div>
            </div>
          </div>
        </tab-content>
        <tab-content title="Empresa" icon="ri-building-4-line">
          <div
            class="w-12/12 border border-green-600  rounded-lg py-3 px-5 mx-auto mt-5"
          >
            <div class="flex w-full mb-5">
              <div class="flex w-full flex-col mr-5">
                <label class="mb-2" for="company">Empresa</label>
                <multiselect
                  id="company"
                  v-model="$v.company.company_name.$model"
                  tag-placeholder="Adicionar"
                  placeholder="Procure aqui..."
                  label="name"
                  track-by="name"
                  :options="associates.filteredOptions"
                  :taggable="true"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  selected-label="Selecionado"
                  @tag="addAssociates"
                  :internal-search="false"
                  @search-change="associatesSearchChange"
                  :disabled="true"
                  @select="fetchDataAssociate"
                >
                  <span slot="noOptions"
                    >Comece a pesquisar para que as empresas apareçam
                    aqui.</span
                  >
                </multiselect>
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="email">E-mail</label>
                <input
                  v-model="$v.company.email.$model"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="email"
                  type="email"
                  placeholder="E-mail da empresa"
                  autocomplete="off"
                />
              </div>
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="phone">Telefone</label>
                <input
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="phone"
                  v-model="$v.company.phone.$model"
                  type="phone"
                  placeholder="Telefone da empresa"
                  autocomplete="off"
                  v-mask="'(##) #####-####'"
                />
              </div>
              <div class="flex w-6/12 flex-col mr-5">
                <div class="mb-2 flex items-center">
                  <label for="site">Site</label>
                  <i
                    class="ri-information-line ml-2"
                    v-tooltip="{
                      content:
                        'Informando esse campo, irá aparecer na página da vaga para o candidato.',
                      placement: 'right'
                    }"
                  ></i>
                </div>
                <input
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="site"
                  v-model="$v.company.site.$model"
                  type="phone"
                  placeholder="https://sitedaempresa.com.br"
                  autocomplete="off"
                />
                <div v-if="$v.company.site.$error">
                  <div class="error" v-if="!$v.company.site.valiUrl">
                    Campo deve conter uma URL válida!
                  </div>
                </div>
              </div>
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="people_contact"
                  >Pessoa de Contato</label
                >
                <input
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="people_contact"
                  v-model="$v.company.people_contact.$model"
                  type="text"
                  placeholder="Pessoa que será responsavel pela vaga"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-full flex-row items-center mr-5">
                <input
                  type="checkbox"
                  v-model="$v.company.link_extern_option.$model"
                  name="vacancy_link"
                  class="mr-2"
                />
                <label for="vacancy_link"
                  >Adicionar link externo (caso o processo seletivo seja feito
                  em outras plataformas de recrutamento online)</label
                >
              </div>
            </div>
            <div class="flex w-full mb-5" v-show="company.link_extern_option">
              <div class="flex w-full flex-col mr-5">
                <input
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="link_vacancy_extern"
                  v-model="$v.company.link_vacancy_extern.$model"
                  type="text"
                  placeholder="https://vaga.com.br/minhavagaaqui"
                  autocomplete="off"
                />
                <div v-if="$v.company.link_vacancy_extern.$error">
                  <div
                    class="error"
                    v-if="!$v.company.link_vacancy_extern.valiUrl"
                  >
                    Campo deve conter uma URL válida!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab-content>
        <tab-content
          title="Endereço da Oportunidade"
          icon="ri-map-pin-user-line"
        >
          <div
            class="w-12/12 border border-green-600  rounded-lg py-3 px-5 mx-auto mt-5"
          >
            <div class="flex w-full mb-5">
              <div class="flex w-3/12 flex-col mr-5">
                <label class="mb-2" for="cep">CEP</label>
                <input
                  id="cep"
                  type="text"
                  v-model="address.cep"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="CEP"
                  v-mask="'#####-###'"
                />
              </div>

              <div class="flex w-7/12 flex-col mr-5">
                <label class="mb-2" for="address">Endereço</label>
                <input
                  id="address"
                  type="text"
                  v-model="address.address"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Endereço"
                />
              </div>

              <div class="flex w-2/12 flex-col mr-5">
                <label class="mb-2" for="number">Número</label>
                <input
                  id="number"
                  type="text"
                  v-model="address.number"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Número"
                />
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="complement">Complemento</label>
                <input
                  id="complement"
                  type="text"
                  v-model="address.complement"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Complemento"
                />
              </div>

              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="neighborhood">Bairro</label>
                <input
                  id="neighborhood"
                  type="text"
                  v-model="address.neighborhood"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Bairro"
                />
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="state">Estado</label>
                <multiselect
                  id="state"
                  v-model="address.selected_state"
                  deselect-label="Clique para remover"
                  track-by="name"
                  label="name"
                  select-label="Clique para selecionar"
                  selected-label="Selecionado"
                  placeholder="Selecione um estado"
                  :options="address.states"
                  @select="fetchCities"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.name }}
                  </template>
                  <span slot="noOptions">Nenhum estado encontrado.</span>
                </multiselect>
              </div>

              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="city">Cidade</label>
                <multiselect
                  id="city"
                  v-model="$v.address.selected_city.$model"
                  deselect-label="Clique para remover"
                  track-by="name"
                  label="name"
                  :taggable="true"
                  :internal-search="false"
                  select-label="Clique para selecionar"
                  selected-label="Selecionado"
                  placeholder="Selecione uma cidade"
                  :options="address.cities.filteredOptions"
                  @search-change="citiesSearchChange"
                >
                  <span slot="noOptions">Nenhuma cidade encontrada.</span>
                </multiselect>
                <div v-if="$v.address.selected_city.$error">
                  <div class="error" v-if="!$v.address.selected_city.required">
                    Campo obrigatório.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout';
import VueTagsInput, { createTag } from '@johmun/vue-tags-input';
import { mask } from 'vue-the-mask';
import { required, helpers, url, between } from 'vuelidate/lib/validators';
import axios from '@/utils/axios';
import { mapGetters } from 'vuex';
import { VMoney } from 'v-money';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';
import moment from 'moment';

const GreaterOrLessThan = param =>
  helpers.withParams({ type: 'date', value: param }, value => value > param);

export default {
  name: 'EditVacancy',

  title() {
    return `${process.env.VUE_APP_NAME} | Editar Vaga`;
  },

  components: {
    DashboardLayout,
    VueTagsInput,
    LoaderAnimation
  },

  directives: { mask, money: VMoney },

  data() {
    return {
      isLoading: true,
      isDisabled: false,
      hiringTimeOption: false,
      benefits: [],
      vacancy: {
        office: '',
        description_vacancy: '',
        requirements: '',
        vacancies_available: '',
        hiring_time: '',
        contracting_regime: {
          options: [
            { label: 'CLT', value: '1' },
            { label: 'PJ', value: '2' },
            { label: 'Estágio', value: '3' },
            { label: 'Jovem Aprendiz', value: '4' },
            { label: 'Menor Aprendiz', value: '5' },
            { label: 'Contratação Eventual PF', value: '6' }
          ],
          selected: null
        },
        office_hours: '',
        salary: '',
        salary_until: '',
        schooling: {
          options: [],
          selected: null
        },
        validity_from: '',
        validity_to: '',
        start_activities: '',
        search: '',
        keyword: '',
        keywords: [],
        benefits: [],
        status: '1',
        visible: 1
      },
      company: {
        company_name: '',
        email: '',
        phone: '',
        site: '',
        people_contact: '',
        link_extern_option: false,
        link_vacancy_extern: ''
      },
      address: {
        cep: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        country: '',
        states: [],
        selected_state: null,
        cities: {
          options: [],
          filteredOptions: [],
          selected: []
        },
        selected_city: null
      },

      associates: {
        options: [],
        filteredOptions: [],
        selected: []
      },

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    };
  },

  validations() {
    return {
      vacancy: {
        office: { required },
        description_vacancy: { required },
        requirements: {},
        vacancies_available: {
          required,
          between: between(1, 999)
        },
        contracting_regime: { selected: { required } },
        hiring_time: {},
        office_hours: { required },
        salary: {},
        salary_until: {},
        schooling: { selected: { required } },
        validity_from: { required },
        validity_to: {
          required,
          valiDate: GreaterOrLessThan(this.vacancy.validity_from)
        },
        keyword: {},
        benefits: {}
      },
      company: {
        company_name: { selected: { required } },
        email: {},
        phone: {},
        site: {
          valiUrl: url
        },
        people_contact: {},
        link_extern_option: {},
        link_vacancy_extern: { valiUrl: url }
      },
      associates: {
        selected: { required }
      },
      address: {
        selected_city: { required }
      }
    };
  },
  computed: {
    ...mapGetters('auth', {
      authUser: 'user',
      authSyndicate: 'syndicate',
      authAssociate: 'associate'
    })
  },
  methods: {
    alterStatus() {
      if ((this.vacancy.status = 2  ) && (this.vacancy.validity_to > moment().format('YYYY-MM-DD')))
        this.vacancy.status = '1';
        console.log(this.vacancy.status)
    },

    onComplete() {
      if (this.$v.vacancy.$invalid) {
        this.$refs.form.changeTab(1, 0);
        this.$v.vacancy.office.$touch();
        this.$v.vacancy.description_vacancy.$touch();
        this.$v.vacancy.vacancies_available.$touch();
        this.$v.vacancy.contracting_regime.selected.$touch();
        this.$v.vacancy.office_hours.$touch();
        this.$v.vacancy.schooling.$touch();
        this.$v.vacancy.validity_from.$touch();
        this.$v.vacancy.validity_to.$touch();
        this.$v.vacancy.benefits.$touch();
      } else if (this.$v.address.$invalid) {
        this.$v.address.selected_city.$touch();
      } else {
        const vacancyAttributes = {
          office: this.vacancy.office,
          description_vacancy: this.vacancy.description_vacancy,
          requirements: this.vacancy.requirements,
          vacancies_available: this.vacancy.vacancies_available,
          contracting_regime: this.vacancy.contracting_regime.selected.value,
          hiring_time: this.vacancy.hiring_time,
          office_hours: this.vacancy.office_hours,
          salary: this.vacancy.salary,
          salary_until: this.vacancy.salary_until,
          schooling: this.vacancy.schooling.selected.name,
          validity_from: this.vacancy.validity_from,
          validity_to: this.vacancy.validity_to,
          start_activities: this.vacancy.start_activities,
          status: this.vacancy.status,
          visible: this.vacancy.visible,
          user_id: this.authUser.id
        };

        const keywordsAttributes = {
          keywords: this.vacancy.keyword
        };

        const benefitsAttributes = {
          id: this.vacancy.benefits
        };

        const companyAttributes = {
          id_associate: this.company.company_name.id_associate,
          company_name: this.company.company_name.name,
          email: this.company.email,
          phone: this.company.phone,
          site: this.company.site,
          people_contact: this.company.people_contact,
          link_extern_option: this.company.link_extern_option,
          link_vancancy_extern: this.company.link_vacancy_extern
        };

        const addressAttributes = {
          city_id: this.address.selected_city.id,
          cep: this.address.cep,
          address: this.address.address,
          number: this.address.number,
          complement: this.address.complement,
          neighborhood: this.address.neighborhood,
          city: this.address.cities.id
        };

        const vacancy = {
          vacancyAttributes: vacancyAttributes,
          keywordsAttributes: keywordsAttributes,
          benefitsAttributes: benefitsAttributes,
          companyAttributes: companyAttributes,
          addressAttributes: addressAttributes
        };
        axios
          .put(`/api/vagas/${this.$attrs.id}/update`, vacancy)
          .then(() => {
            this.$toast.success('Vaga atualizada com sucesso!');
            this.$router.push({ name: 'list-vacancies' });
            // this.$route.go(-1);
          })
          .catch(error => {
            this.$toast.error(error.response.data.errors);
          });
      }
    },

    selectKeyword(keyword) {
      this.vacancy.search = keyword;
      this.vacancy.keywords = [];
    },

    searchKeyword() {
      if (this.vacancy.search.length >= 3) {
        return axios
          .post('api/busca/palavras-chave', {
            search: this.vacancy.search
          })
          .then(response => {
            this.vacancy.keywords = [];

            response.data.data.forEach(keyword =>
              this.vacancy.keywords.push(keyword.label)
            );
          });
      }
    },

    addAssociates(associates) {
      const tag = {
        name: associates.name,
        id: associates.id
      };

      this.options.push(tag);
      this.value.push(tag);
    },

    fetchAssociates() {
      axios.get('/api/associados').then(({ data }) => {
        data.data.forEach(associate => {
          this.associates.options.push({
            name: associate.name,
            id: associate.id
          });
        });
        this.isLoading = false;
        this.verifyUser();
      });
    },

    fetchDataAssociate({ id }) {
      axios.get(`/api/associados/${id}`).then(({ data }) => {
        this.company.phone = data.cellphone;
        this.address.cep = data.address.cep;
        this.address.address = data.address.address;
        this.address.number = data.address.number;
        this.address.neighborhood = data.address.neighborhood;
        this.address.complement = data.address.complement;

        this.address.selected_state = {
          id: data.address.state.id,
          name: data.address.state.name
        };

        this.fetchCities(this.address.selected_state);

        this.address.selected_city = {
          id: data.address.city.id,
          name: data.address.city.name
        };
      });
    },

    async fetchBenefits() {
      await axios.get('/api/vagas/beneficios').then(({ data }) => {
        data.forEach(benefits => {
          this.benefits.push({
            id: benefits.id,
            name: benefits.name
          });
        });
      });
    },

    async fetchStates() {
      await axios.get('/api/estados').then(({ data }) => {
        data.data.forEach(state =>
          this.address.states.push({
            id: state.id,
            name: state.name
          })
        );
      });
    },

    async fetchCities(state) {
      this.address.cities.options = [];
      this.address.selected_city = null;

      await axios.get(`/api/estados/${state.id}/cidades`).then(({ data }) => {
        data.data.forEach(city =>
          this.address.cities.options.push({
            id: city.id,
            name: city.name
          })
        );
      });
    },

    async fetchEducationLevels() {
      const response = await axios.get('api/escolaridades');

      response.data.data.forEach(schooling => {
        this.vacancy.schooling.options.push({
          name: schooling.name,
          order: schooling.order
        });
      });
    },

    async fetchVacancy() {
      const response = await axios.get(`api/vagas/${this.$attrs.id}/editar`);
      response.data.vacancy.forEach(vacancy => {
        this.hiringTimeOption = vacancy.link_extern_option;

        this.vacancy.office = vacancy.office;
        this.vacancy.description_vacancy = vacancy.description_vacancy;
        this.vacancy.requirements = vacancy.requirements;
        this.vacancy.vacancies_available = vacancy.vacancies_available;
        this.vacancy.hiring_time = vacancy.hiring_time;
        this.vacancy.contracting_regime.selected = vacancy.contracting_regime;
        this.vacancy.office_hours = vacancy.office_hours;
        this.vacancy.salary = vacancy.salary;
        this.vacancy.salary_until = vacancy.salary_until;
        this.vacancy.schooling.selected = vacancy.schooling;
        this.vacancy.validity_from = vacancy.validity_from;
        this.vacancy.validity_to = vacancy.validity_to;
        this.vacancy.start_activities = vacancy.start_activities;
        this.vacancy.visible = vacancy.visible;
        this.vacancy.status = vacancy.status.id;

        this.company.company_name = vacancy.company;
        this.company.email = vacancy.email;
        this.company.phone = vacancy.phone;
        this.company.site = vacancy.site;
        this.company.people_contact = vacancy.people_contact;
        this.company.link_extern_option = vacancy.link_extern_option;
        this.company.link_vacancy_extern = vacancy.link_vancancy_extern;

        this.address.cep = vacancy.cep;
        this.address.address = vacancy.address;
        this.address.number = vacancy.number;
        this.address.complement = vacancy.complement;
        this.address.neighborhood = vacancy.neighborhood;
        this.address.selected_state = vacancy.state;

        this.fetchCities(this.address.selected_state);

        this.address.selected_city = vacancy.city;
      });

      response.data.keywords.forEach(keyword => {
        let tag = createTag(keyword.label, [keyword.label]);
        this.$refs.keywords.addTag(tag);
      });

      response.data.benefits.forEach(benefit => {
        this.$v.vacancy.benefits.$model.push(benefit.id);
      });

      if (this.vacancy.hiring_time) {
        this.hiringTimeOption = true;
      }
    },

    citiesSearchChange(search) {
      this.address.cities.filteredOptions = search
        ? this.address.cities.options.filter(option =>
            this.normalizedContains(search, option.name)
          )
        : this.address.cities.options;
    },

    removeDiacritics(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },

    normalizedContains(needle, haystack) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi');
      return regExp.test(this.removeDiacritics(haystack));
    },

    associatesSearchChange(search) {
      this.associates.filteredOptions = search
        ? this.associates.options.filter(option =>
            this.normalizedContains(search, option.name)
          )
        : this.associates.options;
    },

    loadDataAssociate() {
      if (this.authUser.role.name === 'associate') {
        this.company.company_name = this.authUser.name;

        this.associates.selected = {
          id: this.authUser.id,
          name: this.authUser.name
        };
      }
    },

    verifyUser() {
      if (this.authUser.role.name === 'syndicate') {
        this.syndicates.selected.push({
          id: this.authSyndicate.id,
          name: this.authSyndicate.name
        });

        this.isDisabled = true;
      }
    }
  },
  created() {
    this.fetchStates();
    this.fetchAssociates();
    this.fetchBenefits();
    this.fetchEducationLevels();
    this.fetchVacancy();
  }
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
